@import 'node_modules/bulma/bulma.sass';
@import 'node_modules/tiny-slider/src/tiny-slider.scss';
$slick-font-path: "/fonts/";
$slick-font-family: "slick";
$slick-loader-path: "/assets/";
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';


#header{
	nav{
		background: #f6f6f6;
		padding-left:  2rem;
		padding-right: 2rem;
		position: relative;

		.navbar-brand{
			position: relative;
			img{
				position: absolute;
				top: 10px;
				max-width: inherit;
				max-height: 100%;
				z-index: 99;
			}
		}


		.navbar-menu{
			a.navbar-item{
				@media(min-width: 1088px) {
					padding: 1.75rem .75rem;
				}
				transition: 200ms ease-in-out;
				font-family: "Source Sans Pro", sans-serif;


				&.is-active,
				&:focus,
				&:hover{
					background-color: #f69023;
					color: #fff;
				}
			}
		}

	}

	a.navbar-burger{
		color: #f69023;
	}
	@media(max-width: 1087px) {
		.navbar-menu{
			position: absolute;
			right: 0;
			left: 0;
		}
	}
}

#home{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url("/images/banner-home.jpg");
	padding-top: 60px;
	padding-bottom: 60px;


	h3{
		font-family: Exo;
		font-size: 22px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fefefe;
		margin-bottom: 20px;

	}

	p{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 16px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fefefe;
		margin-bottom: 20px;
	}
}

#nosotros{
	padding-top: 60px;
	padding-bottom: 60px;
	h3{
		font-size: 22px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: justify;
		color: #2a2a2a;
		margin-bottom: 30px;
		font-family: Exo, sans-serif;
		text-transform: uppercase;


		span{
			color: #f69023;
		}
	}
	p{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: justify;
		color: #2a2a2a;
		margin-bottom: 18px;

		&.bold{
			font-weight: 600;

		}
		span{

		}
	}



	.mision{
		margin-bottom: 50px;
	}



	span.title {
		font-family: Exo, sans-serif;
		font-size: 22px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;
	}

	.center-vertically{
		@media (max-width: 768px) {
			display: block!important;
		}
	}
}

#why-chose{

	background-image: url("/images/why-choose.png");
	background-position: top center;
	background-size: cover;
	padding-top: 60px;
	padding-bottom: 60px;

	h3{
		font-family: Exo, sans-serif;
		font-size: 21.5px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		margin-bottom: 70px;
	}

	h4{
		font-family: Exo, sans-serif;
		font-size: 22px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
	}

	p{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
	}
}

#numbers{
	background-color: #f69023;
	padding-top: 50px;
	padding-bottom: 30px;
	color: #fff;
	p.title{
		color: #fff;
		margin-bottom: 0px;
	}
	p.heading{
		font-family: "Source Sans Pro", sans-serif;
	}
}

.container{
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

#brands {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	background: #f9f9f9;
	padding-top: 60px;
	padding-bottom: 60px;

	.title{
		font-family: Exo,
		sans-serif;
		font-size: 22px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;
		padding-top: 50px;
	}
}

#metodologies{
	padding-top: 80px;
	padding-bottom: 60px;
	p {
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;

		span{
			font-weight: 600;
		}
	}

	.space-50{
		margin-top: 50px;
	}

	.space-25{
		margin-top: 25px;
	}
}

#risks{
	padding-bottom: 60px;

	h3 {
		font-family: Exo, sans-serif;
		font-size: 18.5px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		margin-bottom: 70px;
	}

	h4{
		font-family: "Source Sans Pro",sans-serif;
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
	}

	.black-border-v{

		border: solid 1px #4b4b4a;
		height: 300px;
		overflow: hidden;

		.text-rotate {
			font-size: 18px;
			font-family: "Source Sans Pro", sans-serif;
			height: 100%;
			-webkit-transform: rotate(270deg);
			transform: rotate(270deg);
			align-items: center;
			justify-content: center;
			display: flex;
			white-space: nowrap;
			@media only screen and (max-width: 480px) {
				font-size: 14px;
			}
		}
	}

	.padding-top{
		padding-top: 10px;
	}

	.black-border {
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		border: solid 1px #4b4b4a;
		height: 50px;
		margin-bottom: 5px;
		@media only screen and (max-width: 480px) {
			font-size: 14px;
		}
	}

	.no-padding{
		padding-top: 0;
		padding-right: 5px;
		padding-left: 5px;
		padding-bottom: 5px;
	}

	.is-vertical-center {
		display: flex;
		align-items: center;
		text-align: center;
		padding-left: 12%;
		width: 100%;
	}

	.is-v-center {
		padding-top: 50px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		align-items: center;
	}

	.custom-padding{
		padding-left: 35%;
	}

	.card{
		background-color: #f69023;
		height: 244px;
		.card-content{
			.title{
				color: #000000;
				font-family: "Source Sans Pro", sans-serif;
				font-size: 18px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				@media only screen and (max-width: 480px) {
					font-size: 14px;
					margin-bottom: 0;
				}
			}
			.subtitle{
				color: #000000;
				font-family: "Source Sans Pro", sans-serif;
				font-size: 18px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				background-color: #ffffff;
				padding: 5px;
				@media only screen and (max-width: 480px) {
					font-size: 14px;
				}
			}
		}
	}

	.title{
		color: #000000;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		@media only screen and (max-width: 480px) {
			font-size: 14px;
		}
	}

	ul{
		list-style-type: disc;
		color: #ffffff;
		text-align: justify;
		padding-left: 30px;
		padding-bottom: 10px;
		@media only screen and (max-width: 480px) {
			padding-bottom: 0px;
			padding-left: 10px;
		}

		li{
			padding-bottom: 10px;
			@media only screen and (max-width: 480px) {
				padding-bottom: 2px;
			}
		}
		@media only screen and (max-width: 480px) {
			font-size: 14px;
		}
	}
}

#language{

	background-image: url("/images/why-choose.png");
	background-position: top center;
	background-size: cover;
	padding-top: 60px;
	padding-bottom: 60px;

	h3{
		font-family: Exo, sans-serif;
		font-size: 21.5px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		margin-bottom: 70px;
	}

	p{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		@media only screen and (max-width: 768px) {
			font-size: 15px;
		}
	}

}

#roles{
	padding-top: 60px;
	padding-bottom: 450px;
	@media only screen and (max-width: 768px) {
		padding-bottom: 250px;
	}

	h3 {
		font-family: Exo, sans-serif;
		font-size: 18.5px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		margin-bottom: 70px;
	}

	.is-vertical-center {
		display: flex;
		align-items: center;
		text-align: center;
	}

	.yellow-brick{
		background-color: #f69023;
		margin: 2px;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		.tiny-img {
			margin-left: 40%;
			height: 25px;
			width: 25px;
			margin-right: 10px;
		}
	}

	.gray-brick{
		background-color: #a6b4ac;
		margin: 2px;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
	}

	.black-brick{
		background-color: #4b4b4a;
		margin: 2px;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		.tiny-img {
			margin-left: 40%;
			height: 25px;
			width: 25px;
			margin-right: 10px;
		}
	}

	.gray-border{
		margin-top: 20px;
		border-top: 1px solid #4b4b4a;
		border-left: 1px solid #4b4b4a;
		border-right: 1px solid #4b4b4a;
		@media only screen and (max-width: 1281px) {
			display: none;
		}
	}

	.regular-text{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #4b4b4a;
	}

	.light-brick{
		border-radius: 5px;
		border: 3px solid #a6b4ac;
		padding: 2px;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		display: flex;
		min-height: 60px;
		align-items: center;
		justify-content: center;
		color: #4b4b4a;
	}

	.black-card {
		position: absolute;
		background-color: #4b4b4a;
		max-height: 0;
		top: 100px;
		transition: .5s max-height ease-in-out;
		overflow: hidden;

		&.card-content {
			padding-top: 40px;
		}

		@media only screen and (max-width: 1080px) {
			max-height: fit-content;
			width: 100%;
		}
	}

	.first-brick {
		background: linear-gradient(to left, white 90%, #a6b4ac 10%);
		position: relative;
		@media only screen and (max-width: 1080px) {
			margin-bottom: 150px;
		}
	}

	.first-brick:hover{
		transition: visibility 0s, opacity 0.5s linear;
	}

	.first-brick:hover .card-1{
		max-height: 500px;
	}


	.second-brick {
		background: linear-gradient(to right, #a6b4ac 95%, white 5%);
		position: relative;
		@media only screen and (max-width: 1080px) {
			margin-bottom: 150px;
		}
	}

	.second-brick:hover{
		transition: .5s max-height ease-in-out;
	}

	.second-brick:hover .card-2 {
		max-height: 500px;
	}

	.third-brick {
		background: linear-gradient(to left, white 95%, #a6b4ac 5%);
		position: relative;
		@media only screen and (max-width: 1080px) {
			margin-bottom: 250px;
		}
	}

	.third-brick:hover {
		transition: .5s max-height ease-in-out;
	}

	.third-brick:hover .card-3 {
		max-height: 500px;
	}

	.fourth-brick {
		background: linear-gradient(to left, white 85%, #a6b4ac 15%);
		position: relative;
		@media only screen and (max-width: 1080px) {
			margin-bottom: 360px;
		}
	}

	.fourth-brick:hover {
		transition: .5s max-height ease-in-out;
	}

	.fourth-brick:hover .card-4 {
		max-height: 540px;
	}

	.fifth-brick {
		background: linear-gradient(to left, white 95%, #a6b4ac 5%);
		position: relative;
		@media only screen and (max-width: 1080px) {
			margin-bottom: 170px;
		}
	}

	.fifth-brick:hover {
		transition: .5s max-height ease-in-out;
	}

	.fifth-brick:hover .card-5 {
		max-height: 500px;
	}

	.sixth-brick {
		background: linear-gradient(to left, white 95%, #a6b4ac 5%);
		position: relative;
	}

	.sixth-brick:hover {
		transition: .5s max-height ease-in-out;
	}

	.sixth-brick:hover .card-6 {
		max-height: 500px;
	}

	ul {
		list-style-type: disc;
		color: #ffffff;
		text-align: left;
		padding-left: 20px;
		padding-bottom: 5px;
		padding-top: 15px;
		li{
			padding-top: 4px;
		}
	}

	.tiny-image{
		height: auto;
		max-width: 100%;
		position: absolute;
		bottom: -85px;
		left: 30%;
		z-index: 99;
		@media only screen and (max-width: 1080px) {
			left: 46%;
		}
		@media only screen and (max-width: 568px) {
			left: 36%;
		}
	}
}

#normativities,
#business{
	padding-top: 60px;
	padding-bottom: 60px;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		padding-left: 1em;
		text-indent: -.7em;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;
	}

	li::before {
		content: "• ";
		color: #f69023;
	}

	h3{
		font-family: Exo, sans-serif;
		font-weight: 600;
		color: #2a2a2a;
		font-size: 18px;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		margin-bottom: 10px;
		text-transform: uppercase;

	}

	h4{
		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #3b3b3b;
	}

	.center-vertically{
		justify-content: center;
	}
}

#normativities-process,
#business-process{
	.top{
		.column{
			padding-bottom: 0;

			.color{
				background-color: #f69023;
				justify-content: center;
				display: flex !important;
				align-items: center;
				width: 100%;
				height: 100%;
				padding-top: 30px;
				padding-bottom: 30px;
			}

			p{
				font-family: "Source Sans Pro", sans-serif;
				font-size: 18px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: #ffffff;

			}

			img{
				margin-right: 10px;
			}

		}
	}

	.bottom{
		.column{
			padding-top: 0;
		}

		h3 {
			font-family: Exo;
			font-size: 32px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #2a2a2a;
			background-color: #f3f3f3;
			border: solid 5px #f69023;
			justify-content: center;
			display: flex !important;
			align-items: center;
			width: 100%;
			height: 100%;
			padding-top: 45px;
			padding-bottom: 45px;
			text-transform: uppercase;
		}
	}
}

#contact{

	padding-top: 80px;
	padding-bottom: 80px;
	h3{

		font-family: Exo;
		font-size: 22px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: justify;
		color: #2a2a2a;
	}
	p{
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;

		margin-bottom: 16px;

		&.phone,
		&.time{
			padding-left: 2.5em;
			position: relative;

			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #2a2a2a;


			span{
				font-weight: 600;
			}
		}

		&.time::before,
		&.phone::before{
			content: ' ';
			position: absolute;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&.time::before{
			background-image: url("/images/time.png");
			width: 35px;
			height: 35px;
			left: 0px;
			top: 4px;
		}

		&.phone::before{
			background-image: url("/images/phone.png");
			width: 30px;
			height: 30px;
			left: 0px;
			top: 8px;
		}
	}

	input, textarea{
		background-color: #f8f8f8;
	}


	input.required, textarea.required{
		border: solid 1px #ff0000;
	}


	span.required{
		font-family: "Source Sans Pro", sans-serif;;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ff0000;
	}

	.button{
		@media (max-width: 1278px) {
			font-size: 1rem;
		}
	}


}

#business-model {
	padding-top: 70px;
	padding-bottom: 70px;

	.head{
		display: flex;
		@media (max-width: 768px) {
			display: block!important;
		}
	}

	h3{
		font-family: Exo;
		font-size: 18.5px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #2a2a2a;
		margin-bottom: 70px;
		text-transform: uppercase;

	}

	h4{
		font-family: Exo;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;
		padding-top: 10px;
		padding-bottom: 10px;

		&.lineal{
			background-color: #a6b4ac;
		}

		&.grafico{
			background-color: #f69023;
			@media (max-width: 768px) {
				margin-top: 50px;
			}
		}
	}

	ul{
		height: 100%;
		border-bottom: solid 3px #a6b4ac;
		padding-top: 30px;
		padding-right: 20px;
		padding-left: 40px;
		background-position: center;
		background-repeat: no-repeat;


		li{
			margin-bottom: 20px;
			font-family: "Source Sans Pro", sans-serif;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #2a2a2a;
		}

	}

	.left ul{
		border-left: solid 3px #a6b4ac;
		background-image: url("/images/ex.png");
		@media (max-width: 768px) {
			border-right: solid 3px #a6b4ac;
		}
	}

	.right ul{
		border-right: solid 3px #a6b4ac;
		background-image: url("/images/check.png");
		position: relative;

		@media (max-width: 768px) {
			border-left: solid 3px ;
			border-color:#f69023;

		}
		@media (min-width: 769px) {
			&:before{
				content: ' ';
				width: 4px;
				background: #a6b4ac;
				height: 60%;
				left: -4px;
				position: absolute;
				top: 20%;
			}
		}
	}
}

#diferencial{
	padding-top: 70px;
	padding-bottom: 70px;

	h4{
		font-family: Exo;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #000000;
		margin-bottom: 40px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		padding-left: 1em;
		text-indent: -.7em;
		margin-bottom: 12px;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;
	}

	li::before {
		content: "• ";
		color: #f69023;
	}
}

#documentation{
	padding-top: 70px;
	padding-bottom: 70px;

	h3{
		font-family: Exo;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		margin-bottom: 70px;

	}

	h4{
		font-family: Exo;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	ul{
		text-align: left;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 2.13;
		letter-spacing: normal;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		padding-left: 1em;
		text-indent: -.7em;
		margin-bottom: 12px;

		font-family: "Source Sans Pro", sans-serif;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #2a2a2a;
	}
	.column{
		padding-top: 36px !important;
		padding-left: 24px !important;
		padding-right: 24px !important;
		padding-bottom: 24px !important;
		img{
			margin-bottom: 16px;
		}
		h4{
			margin-bottom: 16px;

		}
	}


	.increment{

		background-color: #f69023;

		@media (min-width: 769px) {
			box-shadow: 5px 0px 7px 0 rgba(0, 0, 0, 0.11);
			z-index: 10;
		}

		h4{
			color: #000000;
		}

		li{
			color: #ffffff;
		}

		li::before {
			content: "• ";
			color: #ffffff;
		}

	}
	.sales{

		background-color: #a6b4ac;
		@media (min-width: 769px) {
			box-shadow: 5px 0px 7px 0 rgba(0, 0, 0, 0.11);
			z-index: 5;
		}

		h4{
			color: #000000;
		}

		li{
			color: #000000;
		}

		li::before {
			content: "• ";
			color: #000000;
		}
	}
	.media{

		  background-color: #4b4b4a;


		h4{
			color: #ffffff;
		}

		li{
			color: #ffffff;
		}

		li::before {
			content: "• ";
			color: #ffffff;
		}
	}
}

.banner{
	padding-top: 120px;
	padding-bottom: 90px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	h3{
		font-family: Exo, sans-serif;
		font-size: 21.5px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #ffffff;

		span {
			color: #f69023;
		}
	}

}

.benefits{
	background-image: url("/images/banner-benefits.jpg");
}

.contact{
	background-image: url("/images/banner-contacto.jpg");
}

.howitworks{
	background-image: url("/images/banner-howitworks.jpg");
}

.portafolio{
	background-image: url("/images/banner-portafolio.jpg");
}




footer{
	.footer{
		padding-top: 70px;
		padding-bottom: 70px;
		background-color: #575859;
		p{
			font-family: "Source Sans Pro", sans-serif;
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			margin-bottom: 16px;
			position: relative;

			&::before{
				content: ' ';
				position: absolute;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			&.email::before{
				background-image: url("/images/email.png");
				width: 17px;
				height: 21px;
				left: -24px;
				top: 1px;
			}

			&.phone::before{
				background-image: url("/images/phone.png");
				width: 24px;
				height: 24px;
				left: -30px;
			}

			&.address::before{
				background-image: url("/images/address.png");
				width: 29px;
				height: 33px;
				left: -38px;
				top: 4px;
			}
			&.time::before {
				background-image: url("/images/time.png");
				width: 29px;
				height: 29px;
				left: -34px;
				top: 4px;
			}
		}

	}
	.bottom{
		padding-top: 25px;
		padding-bottom: 25px;
		background-color: #1d1d1d;
		color: #ffffff;
		font-family: "Source Sans Pro", sans-serif;

		@media (min-width: 768px) {
			.is-pulled-left-desktop{
				float: left;
			}

			.is-pulled-right-desktop{
				float: right;
			}
		}



		a{
			color: #ffffff;
			transition: 100ms ease-in-out all;

			&:hover{
				color: #02e8b2;
			}
		}

	}
}



.center-vertically{
	display: flex !important;
	align-items: center;
}

.button.shadow{

	box-shadow: 0px 5px 0 0 rgba(70, 70, 69, 0.75);
	bottom: 0;
	transition: 100ms ease-in-out all;

	&.is-active,
	&:active{
		bottom: -5px;
		box-shadow: 0px 0px 0 0 rgba(70, 70, 69, 0.75);

	}
}


.button.orange{
	background-color: #f69023 ;
	font-family: Exo, sans-serif;
	font-size: 22px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fefefe;
	border: 0;
	transition: 100ms ease-in-out all;

	@media (max-width: 1278px) {
		font-size: 1rem;
	}

	&.is-active,
	&:active{
		background-color: darken(#f69023, 20%);
		color: #fefefe;
	}

	&.is-hover,
	&:hover,
	&.is-focus,
	&:focus{
		background-color: darken(#f69023, 10%);
		color: #fefefe;
	}
}
